import Image from "next/legacy/image";

import { convertDate } from "@helper/date";
import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import EastIcon from '@mui/icons-material/East';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useTranslation } from "next-i18next";
import Link from "next/link";
import { CardProps } from ".";
import styles from './../Article/Article.module.scss';


export const CardComplete: React.FC<CardProps> = ({ node }) => {
  const {t} = useTranslation();
  const image: string = node?.field_article_image ? getThumbnailUrl(node.field_article_image) : "/assets/images/img-placeholder-01.jpg";
  const date: string = convertDate(node?.created, node.path.langcode)

  return (
    
    <Link href={node?.path?.alias} passHref>
      <Box className={styles.articleCard}>
        {node?.field_category?.name && <Box sx={{
          bgcolor: 'white',
          position: 'absolute',
          top: 8,
          px: 24,
          py: 4,
          borderTopRightRadius: 8,
          zIndex: 10
        }} >
          <Typography variant="overline" color={'text.primary'}>{node?.field_category?.name}</Typography>
        </Box>}
        {image && <Image src={image} alt={getThumbnailAlt(node.field_article_image)} width={392} height={220} layout="responsive" objectFit="cover" quality={100} />}
        
        <Box className={styles.content}>
          <Typography variant="bodyM" fontWeight="light" color="text.secondary">{date}</Typography>
          <Typography variant="subtitle" component='h5' className={styles.title}>{node.title}</Typography>
        </Box>

        <Box className={styles.button}>
          <Button 
            variant="link"
            startIcon={<EastIcon color="primary" />}
          >{t("news.readAll")}</Button>
        </Box>

      </Box>
    </Link>
  )
}

export default CardComplete