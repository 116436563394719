import { FC, useEffect, useRef, useState } from "react";

import Card, { CardVariantModel } from "@components/Modules/Cards";
import Row from "@components/Modules/Utils/Row/Row";
import useOnScreen from "@hooks/useOnScreen";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { DrupalNode } from "next-drupal";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = { articles: DrupalNode[], cardVariant?: CardVariantModel; };

const DesktopCarousel: FC<Props> = ({ articles, cardVariant }) => {
  const fadeRef = useRef(null);
  const onScreen: boolean = useOnScreen(fadeRef);
  const [index, setIndex] = useState(null);
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const items: DrupalNode[] = articles.slice(0, 5);
  const params = {
    modules: [Pagination],
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: true,
    navigation: false,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: false,
        navigation: {
          prevEl: navigationPrevRef.current
            ? navigationPrevRef.current
            : undefined,
          nextEl: navigationNextRef.current
            ? navigationNextRef.current
            : undefined,
        },
      },
    },
    onBeforeInit: (swiper) => {
      swiperRef.current = swiper;
      setIndex(swiper.activeIndex);
    },
    onSlideChange: (swiper) => {
      setIndex(swiper.activeIndex);
    },
  };

  const [loaded, setLoadead] = useState(false);

  useEffect(() => {
    if (!onScreen) return;
    setLoadead(true);
  }, [onScreen]);

  const onClickPrev = () => {
    swiperRef.current?.slidePrev();
  };
  const onClickNext = () => {
    swiperRef.current?.slideNext();
  };

  return (
    <Fade
      ref={fadeRef}
      in={loaded}
      appear={false}
      timeout={1000}
      style={{ transitionDelay: "100ms" }}
    >
      <Container
        maxWidth={false}
        sx={{
          overflow: "hidden",
        }}
      >
        <Row my={50} justifyContent="center">
          <Grid item lg={2} display="flex" alignItems="center">
            {!(index === 0) && (
              <Fab
                color="primary"
                ref={navigationPrevRef}
                onClick={onClickPrev}
              >
                <ArrowBack />
              </Fab>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              "& .swiper": {
                paddingTop: "2",
                paddingBottom: "2rem",
                overflow: "visible",
                "& .swiper-slide": {
                  opacity: "0.3",
                  transition: "opacity .25s linear",
                  pointerEvents: "none",
                  "&.swiper-slide-active, &.swiper-slide-next": {
                    opacity: "1",
                    pointerEvents: "initial",
                  },
                },
              },
            }}
          >
            <Swiper id="desktop-carousel" {...params}>
              {items.map((article) => {
                return (
                  <SwiperSlide key={article.id}>
                    <Card variant={cardVariant} node={article} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>

          <Grid
            item
            lg={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!(index + 2 === items.length) && (
              <Fab
                color="primary"
                ref={navigationNextRef}
                onClick={onClickNext}
              >
                <ArrowForward />
              </Fab>
            )}
          </Grid>
        </Row>
      </Container>
    </Fade>
  );
};

const ArticleCardCarousel: FC<Props> = ({ articles, cardVariant }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            "& .swiper-pagination-bullet": {
              height: "0.7rem",
              width: "0.7rem",

              "&-active": {
                background: "#4F9E30",
              },
            },
          }}
        >
          <Swiper
            id="mobile-carousel"
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {articles.map((article) => (
              <SwiperSlide key={article.id}>
                <Card variant={cardVariant} key={article?.id} node={article} />
              </SwiperSlide>
            ))}
          </Swiper>

          <Box height="4rem" />
        </Box>
      ) : (
        <DesktopCarousel articles={articles} />
      )}
    </>
  );
};

export default ArticleCardCarousel;
